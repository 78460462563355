export const testLevel =
            [{type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_blu"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_org"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_bgl"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_ogl"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
             {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}]

export const LEVEL_ZERO = {
                mapData:
                    [{type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_bgl"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_ogl"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_blu"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_org"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}],
                playerOneIndex: 81,
                playerTwoIndex: 88,
                initialPlayerOneIndex: 81,
                initialPlayerTwoIndex: 88,
                initialBlueBlocks: [61],
                initialOrangeBlocks: [68],
                initialBlueDrop: [21],
                initialOrangeDrop: [28],
                mapX: 10,
                mapY: 10,
                mapClearCon: 2
            }

export const LEVEL_ONE = {
                mapData:
                    [{type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_bgl"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_blu"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_ogl"}, {type:"e_air"}, {type:"e_air"}, {type:"e_org"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
                     {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}],
                playerOneIndex: 84,
                playerTwoIndex: 58,
                initialPlayerOneIndex: 84,
                initialPlayerTwoIndex: 58,
                initialBlueBlocks: [44],
                initialOrangeBlocks: [54],
                initialBlueDrop: [14],
                initialOrangeDrop: [51],
                extra_walls: [],
                mapX: 10,
                mapY: 10,
                mapClearCon: 2
            }

export const LEVEL_TWO = {
    mapData:
        [{type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_bgl"}, {type:"e_wal"}, {type:"e_ogl"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_org"}, {type:"e_blu"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_org"}, {type:"e_blu"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_air"}, {type:"e_org"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_blu"}, {type:"e_air"}, {type:"e_wal"},
        {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}],
    playerOneIndex: 13,
    playerTwoIndex: 18,
    initialPlayerOneIndex: 13,
    initialPlayerTwoIndex: 18,
    initialBlueBlocks: [79, 86, 96],
    extra_walls: [36, 37, 38, 48, 58, 59, 60],
    initialOrangeBlocks: [78, 90, 85],
    initialBlueDrop: [48],
    initialOrangeDrop: [50],
    mapX: 11,
    mapY: 10,
    mapClearCon: 2
}

export const LEVEL_THREE = {
    mapData:
    [
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_ogl"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_org"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_org"}, {type: "e_org"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_blu"}, {type: "e_org"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_org"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_org"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_org"}, {type: "e_org"}, {type: "e_blu"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_bgl"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}],
    playerOneIndex: 13,
    playerTwoIndex: 18,
    initialPlayerOneIndex: 13,
    initialPlayerTwoIndex: 18,
    initialBlueBlocks: [46, 52, 69, 86],
    initialOrangeBlocks: [45, 63, 62, 70, 73, 80, 84, 85],
    extra_walls: [27, 38, 48, 49, 60, 58, 71, 82, 93, 89],
    initialBlueDrop: [96],
    initialOrangeDrop: [24],
    mapX: 11,
    mapY: 10,
    mapClearCon: 2
}

export const LEVEL_SIX = {
    mapData:
    [
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_bgl"}, {type: "e_wal"}, {type: "e_bgl"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_bgl"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_ogl"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_org"}, {type: "e_air"}, {type: "e_blu"}, {type: "e_ogl"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_org"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_ogl"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_org"}, {type: "e_bgl"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_blu"}, {type: "e_wal"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_ogl"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"},
        {type: "e_wal"}, {type: "e_org"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_bgl"}, {type: "e_air"}, {type: "e_air"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_org"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_ogl"}, {type: "e_air"}, {type: "e_wal"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}],
    playerOneIndex: 131,
    playerTwoIndex: 138,
    initialPlayerOneIndex: 131,
    initialPlayerTwoIndex: 138,
    initialBlueBlocks: [23,27,62,87,114],
    initialOrangeBlocks: [25,32,64,101,125],
    extra_walls: [],
    initialBlueDrop: [12,14,18,65,111],
    initialOrangeDrop: [22,28,61,91,135],
    mapX: 10,
    mapY: 15,
    mapClearCon: 10
}

export const LEVEL_FIVE = {
    mapData:
        [{type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_ogl"}, {type:"e_air"}, {type:"e_ogl"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_org"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_org"}, {type:"e_air"}, {type:"e_org"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_org"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_ogl"}, {type:"e_air"}, {type:"e_ogl"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_air"}, {type:"e_wal"},
         {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"}, {type:"e_wal"} ],
    playerOneIndex: 40,
    playerTwoIndex: 16,
    initialPlayerOneIndex: 40,
    initialPlayerTwoIndex: 16,
    initialBlueBlocks: [],
    initialOrangeBlocks: [31,39,41,49],
    initialBlueDrop: [],
    initialOrangeDrop: [21, 23, 57, 59],
    mapX: 9,
    mapY: 9,
    mapClearCon: 4
}

export const LEVEL_FOUR = {
    mapData: [
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_org"}, {type: "e_bgl"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_org"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_org"}, {type: "e_org"}, {type: "e_blu"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_org"}, {type: "e_air"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_air"}, {type: "e_org"}, {type: "e_air"}, {type: "e_blu"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_org"}, {type: "e_org"}, {type: "e_blu"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_air"}, {type: "e_air"}, {type: "e_ogl"}, {type: "e_blu"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_air"}, {type: "e_wal"}, 
        {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}, {type: "e_wal"}],
        mapX: 10,
        mapY: 9,
        initialBlueBlocks: [35, 36, 43, 48, 55, 56, 63, 74],
        initialOrangeBlocks: [16, 27, 33, 34, 41, 46, 53, 54],
        initialBlueDrop: [17],
        initialOrangeDrop: [73],
        playerOneIndex: 44,
        playerTwoIndex: 45,
        initialPlayerOneIndex: 44,
        initialPlayerTwoIndex:  45,
        mapClearCon: 2,
}